/* eslint-disable global-require */
import React from 'react';
import Head from 'next/head';
import Router from 'next/router';

import { Page } from '@/components/layout';
import { SiteError } from '@/components/pages';
import generateMetaTags from '@/utils/meta';

const Custom404 = () => {
  return (
    <Page>
      <Head>
        {generateMetaTags({
          title: '404 Not Found',
          description:
            'Oops! Sorry we couldn’t find the page you are looking for.',
          image: require('@/images/error-image.jpg'),
        })}
      </Head>
      <SiteError
        errorText="Oops! Sorry we couldn’t find the page you are looking for."
        buttonText="Go to home"
        onClick={() => {
          Router.push('/');
        }}
      />
    </Page>
  );
};

export default Custom404;
